const displayFormat = 'ddd DD MMM YY'

/**
 * Determines whether dates in an array are consecutive
 * find the difference between the first and last dates in the array.
 * if they equal the length of the array they must be consecutive.
 *
 * @param { String } start
 * @param { String } end
 * @param { Number } total
 * @param moment
 * @returns { Boolean }
 */
export const consecutiveDates = (
  start: string,
  end: string,
  total: number,
  moment
): boolean => {
  const a = moment(start)
  const b = moment(end)
  const diff = b.diff(a, 'days')

  return diff === total - 1
}

/**
 * Determines whether dates in an array are from the same calendar month
 * @param { String } start
 * @param { String } end
 * @param { Number } total
 * @returns { Boolean}
 */

export const isSameMonth = (start: string, end: string): boolean => {
  const start_date = new Date(start)
  const end_date = new Date(end)

  if (start_date.getFullYear() === end_date.getFullYear()) {
    return start_date.getMonth() === end_date.getMonth()
  } else {
    return false
  }
}

/**
 * Determines whether dates in an array are from the same year
 * @param { String } start
 * @param { String } end
 * @param { Number } total
 * @returns { Boolean}
 */

export const isSameYear = (start: string, end: string): boolean => {
  const [start_date, end_date] = [new Date(start), new Date(end)]

  return start_date.getFullYear() === end_date.getFullYear()
}

/**
 * Returns a summarised dates string for us in event cards etc.
 * @param { String } start
 * @param { String } end
 * @param { Number } instance_count
 * @param { Moment } moment
 * @param { String } eventType
 * @returns { string }
 */

export const formatSummarisedDates = (
  start: string,
  end: string,
  instance_count: number,
  moment,
  eventType?: string | null
): string | null => {
  if (instance_count > 0) {
    const sameMonth = isSameMonth(start, end)
    const datesAreConsecutive = consecutiveDates(
      start,
      end,
      instance_count,
      moment
    )

    if (instance_count === 1) {
      // if there's only one other date, return it
      return moment(start).format(displayFormat)
    } else if (instance_count === 2) {
      if (sameMonth) {
        return [
          // if the start and end dates in the array are in the same month, format the first date as Fri 10.
          // join the dates with - or + if the dates are consecutive or not.
          moment(start).format('ddd DD'),
          moment(end).format(displayFormat),
        ].join(datesAreConsecutive ? ' - ' : ' + ')
      } else {
        // join the dates array with - or + if the dates are consecutive or not.
        return [
          moment(start).format(displayFormat),
          moment(end).format(displayFormat),
        ].join(datesAreConsecutive ? ' - ' : ' + ')
      }
    } else if (datesAreConsecutive) {
      if (sameMonth) {
        // if there are more than 2 dates and they are consecutive, show the range with the first date's format as Fri 10.
        return `${moment(start).format('ddd DD')} - ${moment(end).format(
          displayFormat
        )}`
      } else {
        // if the dates are consecutive, but not the same month, show the range
        return `${moment(start).format(displayFormat)} - ${moment(end).format(
          displayFormat
        )}`
      }
    } else if (eventType !== 'class') {
      // if there are more than 2 non-consecutive dates, show first date and 'X more'
      return `${moment(start).format(displayFormat)} and ${
        instance_count - 1
      } more`
    } else if (eventType === 'class' && isSameYear(start, end)) {
      return `${moment(start).format('ddd DD MMM')} - ${moment(end).format(
        displayFormat
      )}`
    } else {
      return `${moment(start).format(displayFormat)} - ${moment(end).format(
        displayFormat
      )}`
    }
  }

  return null
}

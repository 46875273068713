




















































import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import UICmsImageWithCaption from '@UI/components/media/UICmsImageWithCaption.vue'
import { CmsImage } from '@Core/@types/skyway'
import { SwiperOptions } from 'swiper'
import ArrowLeftSvg from '~/static/images/icons/arrow-left.svg?inline'
import ArrowRightSvg from '~/static/images/icons/arrow.svg?inline'
import 'swiper/swiper.scss'

@Component({
  components: {
    Swiper,
    SwiperSlide,
    ArrowLeftSvg,
    ArrowRightSvg,
    UICmsImageWithCaption,
  },
})
export default class UISlider extends Vue {
  @Prop({ type: Array, required: true }) items!: CmsImage[]
  @Prop({ type: Boolean, default: true }) showPagination!: boolean
  @Prop({ type: Boolean, default: true }) showNavigation!: boolean
  @Prop({ type: String, required: true }) itemComponent!: string
  @Prop({ type: String, default: 'aspect-4-3' }) imageRatio!: string
  @Prop({
    type: Object,
    default: () => {
      return {
        slidesPerView: 1,
        spaceBetween: 16,
        breakpoints: {
          320: {
            slidesPerView: 1.1,
            slidesPerGroup: 1,
            spaceBetween: 16,
          },
          768: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            spaceBetween: 24,
          },
          1024: {
            slidesPerView: 3,
            slidesPerGroup: 3,
            spaceBetween: 32,
          },
        },
      }
    },
  })
  options!: SwiperOptions

  public swiperInstance: any = null

  get optionsCompiled(): SwiperOptions {
    const options = this.options
    const pagination = {
      el: '.swiper-pagination',
      clickable: true,
      renderBullet(index, className) {
        return `<span class="${className} swiper-pagination__bullet"></span>`
      },
    }

    const navigation = {
      nextEl: '.swiper-button--next',
      prevEl: '.swiper-button--prev',
    }

    return {
      ...options,
      pagination,
      navigation,
    }
  }

  async mounted() {
    await this.$nextTick()
    this.swiperInstance =
      this.$refs.instance && this.$refs.instance.swiperInstance
  }
}


































import { Component, Prop, Vue, Emit } from 'nuxt-property-decorator'
import EventCard from '@Events/components/events/EventCard.vue'
import EventsCarouselCard from '@Marketing/components/content/featured-events/EventsCarouselCard.vue'
import UISlider from '@UI/components/media/UISlider.vue'
import { Event } from '@Core/@types/skyway'
import { SwiperOptions } from 'swiper'
import ArrowLeftSvg from '~/static/images/svgs/gallery-arrow-left.svg?inline'
import ArrowRightSvg from '~/static/images/svgs/gallery-arrow-right.svg?inline'

Vue.component('EventCard', EventCard)
Vue.component('EventsCarouselCard', EventsCarouselCard)

@Component({
  components: {
    UISlider,
    ArrowLeftSvg,
    ArrowRightSvg,
  },
})
export default class EventsCarousel extends Vue {
  @Prop({ type: Array, required: true }) events!: Event[]
  @Prop({ type: Object }) options?: SwiperOptions
  @Prop({
    type: String,
    default: 'EventsCarouselCard',
    validator(value) {
      return ['EventsCarouselCard', 'EventCard'].includes(value)
    },
  })
  component!: string

  protected increment: number = 0

  onSliderResized() {
    this.increment++
  }

  get perPage(): number {
    if (
      this.$mq === 'screen375' ||
      this.$mq === 'screen480' ||
      this.$mq === 'screen640'
    ) {
      return 1
    } else if (this.$mq === 'screen768' || this.$mq === 'screen1024') {
      return 2
    } else {
      return 3
    }
  }

  @Emit('selectEvent')
  selectEvent(value: number) {
    return value
  }
}






















































































import { Component, mixins } from 'nuxt-property-decorator'
import EventCardMixin from '@Events/mixins/events/EventCard.vue'
import InfoIcon from '~/static/images/icons/info.svg?inline'

@Component({
  inheritAttrs: false,
  components: {
    InfoIcon,
  },
})
export default class EventCard extends mixins<EventCardMixin>(EventCardMixin) {
  get accessTags(): string[] | null {
    return this.data.accessTags
  }
}















import { Component } from 'nuxt-property-decorator'
import UICmsImage from '@UI/components/media/UICmsImage.vue'

@Component
export default class UICmsImageWithCaption extends UICmsImage {}

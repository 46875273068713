




import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { CmsImage, CmsLink, Event } from '@Core/@types/skyway'
import { formatImage } from '@Core/helpers/imageFormatter'
import { formatSummarisedDates } from '@Marketing/helpers/dates'
import InfoIcon from '~/static/images/icons/info.svg?inline'

@Component({
  inheritAttrs: false,
  components: {
    InfoIcon,
  },
})
export default class EventCardMixin extends Vue {
  @Prop({ type: Object, required: true }) data!: Event

  protected mouseX: number = 0
  protected mouseY: number = 0

  get pageRedirectUrl(): string {
    return `${this.$config.get('URLS').whats_on}${this.seriesAlias}${
      this.data.alias
    }`
  }

  get hasQuickLink(): boolean {
    const { instance_count, next_instance } = this.data

    return !!(instance_count === 1 && next_instance && !this.hasPassed)
  }

  get hasPassed() {
    return this.$moment().isAfter(this.$moment(this.data.last_date))
  }

  get quickLink(): CmsLink | null {
    const { next_instance } = this.data

    const firstPart =
      this.data.type === 'class'
        ? this.$config.get('URLS').classes
        : this.$config.get('URLS').select_area
    return this.hasQuickLink && next_instance && next_instance.instance_ref
      ? {
          slug: `${firstPart}${next_instance.instance_ref}`,
          title: 'Quick Book',
        }
      : null
  }

  get ageRange(): string | null {
    if (this.data.extra && this.data.extra.workshop_min_age) {
      const hasAgeRange = !!(
        this.data.extra.workshop_min_age || this.data.extra.workshop_min_age
      )

      if (hasAgeRange) {
        let ageRangeString: string = 'Ages '

        if (this.data.extra.workshop_min_age) {
          ageRangeString += this.data.extra.workshop_min_age
        }

        if (this.data.extra.workshop_max_age) {
          ageRangeString += '-' + this.data.extra.workshop_max_age
        } else {
          ageRangeString += '+'
        }

        return ageRangeString
      }
    }
    return null
  }

  get seriesTitle(): string | null {
    return this.data.series ? this.data.series.title! : null
  }

  get seriesAlias(): string {
    return this.data.series ? this.data.series.alias + '/'! : ''
  }

  get transformedImages(): CmsImage | null {
    const { image, images } = this.data as Event & { image: string }

    if (images && images.sizes) {
      return images
    } else if (images) {
      return formatImage(images)
    } else if (!images && image) {
      return formatImage({ large: image })
    } else {
      return null
    }
  }

  get imageType(): string | null {
    return this.data.extra && this.data.extra.background_image_type
      ? this.data.extra.background_image_type
      : null
  }

  get imageSize(): string {
    switch (this.imageType) {
      case 'cover':
        return 'large'
      case 'contain':
        return 'banner'
      case null:
        return 'large'
      default:
        return 'large'
    }
  }

  get date(): string | null {
    const { first_date, last_date, instance_count, type } = this.data

    if (this.hasPassed) {
      return this.$moment(this.data.first_date).format('ddd DD MMM YY')
    }

    return formatSummarisedDates(
      first_date,
      last_date,
      instance_count,
      this.$moment,
      type
    )
  }

  /**
   * Get mouse x/y to calculate hover gradient
   */
  onMouseMove(e) {
    this.mouseX = e.offsetX
    this.mouseY = e.offsetY
  }
}




















































































import { Component, mixins } from 'nuxt-property-decorator'
import EventCardMixin from '@Events/mixins/events/EventCard.vue'

@Component
export default class EventsCarouselCard extends mixins<EventCardMixin>(
  EventCardMixin
) {}
